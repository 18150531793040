import { FC, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../Core/Store/hooks';
import { signIn } from '../../../Service/State/authSlice/authSlice';
import { InputText } from 'primereact/inputtext';
import { Label, Root, Title } from './Login.style';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { userLogin } from '../../../Service/State/authSlice/auth.api';
import { setLoading } from '../../../Service/State/spinnerSlice/spinnerSlice';

const Login: FC = () => {
  // initial value for login form
  const defaultValues: any = {
    email: '',
    password: '',
  };
  // set loading to false
  useEffect(() => {
    dispatch(setLoading(false));
  });

  //navigator to navigate to home if login success
  const navigate = useNavigate();

  //store actions to store credentials to the store
  const dispatch = useAppDispatch();

  //hook form to generate form data
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  //form on submit
  /**
   * @todo
   * make axios call
   * send the data to store from dispatch
   * send the token to localStorage
   * navigate to my home if signIn successful
   * @param
   * data{email,password}
   * @description
   * get the email and password then login if success go to home
   */
  const onSubmit = async (data: { email: string; password: string }) => {
    dispatch(setLoading(true));
    const userData = await userLogin(data);
    dispatch(signIn(userData.token));
    navigate('/');
  };

  /**
   *
   * @param name
   * @description handel error in the login form
   * @returns Component with error message
   */
  const getFormErrorMessage = (name: string) => {
    return errors[`${name}`] && <small className="p-error">{errors ? errors[`${name}`]?.message : null}</small>;
  };

  return (
    <Root>
      <Title fontSize="32px" position="center">
        Welcome to Circle!
      </Title>
      <Title fontSize="24px" position="left">
        Login
      </Title>
      <div className=" form-div">
        <div className="card">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <div className="p-field">
              <Label
                className={classNames({
                  'p-error': errors[`email`],
                })}
                htmlFor="email"
              >
                Email
              </Label>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Email is required.',
                  pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id="email"
                    type="email"
                    {...field}
                    className={classNames({
                      'p-invalid': fieldState.invalid,
                    })}
                    placeholder="Email"
                  />
                )}
              />
              {getFormErrorMessage('email')}
            </div>
            <div className="p-field">
              <Label
                className={classNames({
                  'p-error': errors[`password`],
                })}
                htmlFor="password"
              >
                Password
              </Label>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: 'Password is required.',
                  minLength: { value: 6, message: 'At least 6 digits' },
                }}
                render={({ field, fieldState }) => (
                  <Password
                    id="password"
                    {...field}
                    className={classNames({
                      'p-invalid': fieldState.invalid,
                    })}
                    placeholder="Password"
                    toggleMask
                    feedback={false}
                  />
                )}
              />
              {getFormErrorMessage('password')}
            </div>

            <Button type="submit" label="Login" className="p-mt-4" />
            <Link to="forgot-password">Forgot My Password?</Link>
          </form>
        </div>
      </div>
    </Root>
  );
};

export default Login;
