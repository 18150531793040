import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance, { axiosRefresh } from '../../Core/AxiosInstance/axiosInstance';
import { useAppDispatch } from '../../Core/Store/hooks';
import { signOut } from '../State/authSlice/authSlice';
import { setLoading } from '../State/spinnerSlice/spinnerSlice';

const Interceptors = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    /**
     * @todo intercept any request and add the token to every request
     * @return the request with the token
     */
    axiosInstance.interceptors.request.use(
      function (config: any) {
        //check if iam not in login page
        if (config.url !== '/login') {
          const localStorageToken = localStorage.getItem('token');
          //add the token from local storage to the header request
          config.headers.Authorization = localStorageToken ? `Bearer ${JSON.parse(localStorageToken).access_token}` : '';
        }
        //return the request with the token
        return config;
      },
      (error: any) => {
        //if err don't do any thing and i will handel it in my global handel error
        return Promise.reject(error);
      }
    );

    /**
     * @todo intercept any request and add the refresh token when the token is expired
     * @return the request with the refresh token
     */
    axiosRefresh.interceptors.request.use(function (config: any) {
      if (config.url !== '/login') {
        const token = localStorage.getItem('refreshToken');

        config.headers.Authorization = token ? `Bearer ${JSON.parse(token).access_token}` : '';
      }
      return config;
    });

    /**
     * @todo intercept any request and handel the global errors and show the right toaster
     * @return the error promise
     */
    axiosInstance.interceptors.response.use(
      (res: any) => {
        // dispatch(setLoading(false));
        return res;
      },
      async (err: any) => {
        if (err.response) {
          //when the Access Token is expired
          if (err.response.status === 401) {
            // if the refresh token expired clear the local storage and navigate to login
            toast.error(err.response.data.message, {
              position: toast.POSITION.TOP_CENTER,
              toastId: 'un-auth-error',
            });
            localStorage.clear();
            navigate('/login');
            dispatch(signOut());
            return Promise.reject(err);
          }
          // with validations errors show toasters
          else if (err.response.status === 422) {
            for (const key in err.response.data.errors) {
              const element = err.response.data.errors[key];
              for (const errMessage of element) {
                toast.error(errMessage, {
                  position: toast.POSITION.TOP_CENTER,
                  toastId: 'err-validation-msg',
                });
              }
            }
            if (err.response.data.message) {
              toast.error(err.response.data.message, {
                position: 'top-center',
                toastId: 'message-error',
              });
            }
          }
          // else just show error message with
          // validations errors
          else if (err.response.status === 403) {
            toast.error(err.response.data.message, {
              position: toast.POSITION.TOP_CENTER,
              toastId: 'forbidden-error',
            });
          } else if (err.response.status === 429) {
            toast.error(err.response.data.message, {
              position: toast.POSITION.TOP_CENTER,
              toastId: 'too-many-req',
            });
          }
          // servers error
          else if (err.response.status === 404 || err.response.status === 500) {
            toast.error('Something went wrong', {
              position: toast.POSITION.TOP_CENTER,
              toastId: 'server-error',
            });
          }
          //internet and fire wall errors
          else if (err.response.status === 0) {
            toast.error('No internet connection', {
              position: toast.POSITION.TOP_CENTER,
              toastId: 'no-connection',
            });
          } else {
            toast.error('Something went wrong', {
              position: toast.POSITION.TOP_CENTER,
              toastId: 'global-error',
            });
          }
        } else {
          toast.error('Something went wrong', {
            position: toast.POSITION.TOP_CENTER,
            toastId: 'uniqueId',
          });
        }
        if (err && !err.response) {
          toast.error('Something went wrong', {
            position: toast.POSITION.TOP_CENTER,
            toastId: 'uniqueId',
          });
          localStorage.clear();
          navigate('/login');
        }
        dispatch(setLoading(false));
        return Promise.reject(err);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default Interceptors;
